<template>
  <div class="flex flex-col">
    <div
      class="flex flex-col items-center justify-center h-full"
      v-if="!success"
    >
      <img src="/favicon.ico" class="h-32 w-32" />
      <span class="mb-4 w-4/12 text-center"
        >Aktiveeri 14 oma päevane tasuta Gewodo Premium prooviperiood, proovi
        funktsioone ning katseta Gewodot ka oma tööprotsessides.</span
      >
      <button @click="confirmModalActive = true" class="new-button-green">
        <span class="typcn typcn-plus icon"></span>
        <span class="label">Aktiveeri</span>
      </button>
      <confirm-modal
        v-if="confirmModalActive"
        @confirm="activateTrial"
        @closeModal="confirmModalActive = false"
        text="Olete kindel, et soovite prooviperioodi aktiveerida?"
      ></confirm-modal>
    </div>
    <div class="flex flex-col items-center justify-center" v-if="success">
      <span class="mb-4">Prooviperiood aktiveeritud!</span>
      <button @click="$router.push('/calendar')" class="new-button-green">
        <span class="typcn typcn-chevron-right icon"></span>
        <span class="label">Tagasi töölauale</span>
      </button>
    </div>
  </div>
</template>

<script>
import ConfirmModal from "@/components/reusable/ConfirmModal";
import RequestHandler from "@/assets/mixins/RequestHandler";
export default {
  components: {
    ConfirmModal
  },
  mixins: [RequestHandler],
  data() {
    return {
      confirmModalActive: false,
      success: false
    };
  },
  mounted() {
    if (this.$store.state.companyData.activeCompany.trial_period_used) {
      this.$router.push("/subscriptions");
    }
  },
  methods: {
    activateTrial() {
      this.apiRequest(
        "subscription/trial/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/",
        "post",
        true
      ).then(res => {
        if (res.status === 201) {
          this.success = true;
          this.$store.dispatch("companyData/retrieveCompanyData");
        }
      });
    }
  }
};
</script>
